import React, { useEffect, useState, useContext } from "react"
import { graphql } from "gatsby"

import "../../styles/pages/sunglasses.scss"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import GlassesHeading from "../../components/GlassesHeading"
import Breadcrumbs from "../../components/Breadcrumbs"
import GlassesFilter from "../../components/GlassesFilter"
import GlassesList from "../../components/GlassesList"
import ProductBanner from "../../components/ProductBanner"
import { useDebounce, useGlasses } from "../../services/hooks"
import CartContext from "../../context/cartContext"


const Sunglasses = ({ data, location }) => {
  const hubbleFrames = data.hubbleapi.hubbleFrameProducts.filter(
    ({ collectionString, sunglassesAvailable }) => {
      const collectionArray = collectionString.split(",")

      return (
        collectionArray.includes("Hubble") &&
        !collectionArray.includes("Modern") &&
        sunglassesAvailable
      )
    }
  )

  const right_side_text =
    "Our sunglasses come with polarized lenses that block 99% of UVA/UVB rays and eliminate daytime glare. But you know what really makes them shine? You can add your prescription to every single pair."
  const list_items = [
    "Quality first. Women’s Sunglasses at prices you can’t believe; anti-reflective, anti-glare, anti-scratch, hydrophobic and oleophobic coatings come standard on every pair, ensuring that your eyes are fully protected from the sun’s harmful rays.",
    "Comfort Matters. With durable barrel hinges and padded temple tips for extra comfy cush.",
    "Personalized just for you. Ease your eyes—in addition to your custom prescription, add reader magnification to any pair our women’s sunglasses.",
    "Made with care. Every pair is made to order and hand-assembled.",
    "Designed for you. Make a statement with your sunnies: Rock a retro cat eye, wow them with wide-frames or look sleek in silver. From classic styles to trendy designs, we have something special for every woman."
  ]

  const { setHeaderColor } = useContext(CartContext)

  const defaultFilter = {
    price: [],
    width: [],
    shape: [],
    material: [],
    color: [],
    gender: ["female"],
  }

  const params = new URLSearchParams(location.search)

  params.forEach((value, key) => {
    defaultFilter[key] = value.split(",")
  })

  const [filter, setFilter] = useState(defaultFilter)

  const debouncedFilter = useDebounce(filter, 500)

  useEffect(() => {
    const params = new URLSearchParams(location.search)

    params.forEach((value, key) => {
      defaultFilter[key] = value.split(",")
    })

    setFilter(defaultFilter)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  useEffect(() => {
    setHeaderColor("green")

    return () => {
      setHeaderColor("black")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const sortedGlasses = useGlasses(hubbleFrames, debouncedFilter)

  useEffect(() => {
    if (typeof window !== undefined ) {
      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push({
        event: "view_item_list",
        ecommerce: {
          items: sortedGlasses.map(( product, index ) => (
            {
              index: index,
              item_id: product.shopifyProductId,
              item_name: product.title,
              currency: "USD",
              discount: 0,
              item_brand: "Hubble",
              item_category: "frames",
              item_category2: 'sunglasses',
              item_category3: product.productGender,
              item_category4: product.material,
              item_category5: product.size,
              item_list_id: `${product.productGender}-Sunglasses-Womens`,
              price: parseFloat(product.glassesBasePrice),
            }
          ))
        }
      })
    }
  }, [sortedGlasses])

  const schemaMarkup = [{
    "@url": "https://hubblecontacts.com/sunglasses/womens-sunglasses",
    "@type": "webpage",
    "name": "Womens Sunglasses: Prescription Sunglasses Online | Hubble",
    "description": "Hubble has the latest trends in womens sunglasses. Shop our selection of womens prescription sunglasses available in a variety of styles, guaranteeing you'll find a pair you love.",
    "publisher": {
      "@type": "Organization",
      "name": "Hubble Contacts"
    }
  }]

  return (
    <Layout>
      <SEO
        title="Womens Sunglasses: Prescription Sunglasses Online"
        description="Hubble has the latest trends in womens sunglasses. Shop our selection of womens prescription sunglasses available in a variety of styles, guaranteeing you'll find a pair you love."
        schemaMarkup={schemaMarkup} />
      <div className="sunglasses-content">
        <GlassesHeading
          color="green"
          title="Women's Sunglasses"
          description="Shop our stylish, designer sunglasses for women with polarized lenses that
                        block 99% of UVA/UVB rays and eliminate daytime glare. Complement your
                        unique style without sacrificing convenience and comfort with our
                        handcrafted lenses and frames, all available with or without a prescription."
        />


        <div className="glasses-container">
          <div className="grid-section">
            <Breadcrumbs
              color="green"
              links={[{ to: "/", label: "Homepage" }, { label: "Sunglasses" }]}
            />
            <GlassesFilter
              type="sunglasses"
              glassesLength={sortedGlasses.length}
              filter={filter}
              setFilter={setFilter}
            />
            <GlassesList
              type="sunglasses"
              glasses={sortedGlasses}
              color={filter.color}
              gender={filter.gender[0]}
            />
            <ProductBanner
              title={"Sunglasses for women"}
              right_side_text={right_side_text}
              list_items={list_items}
              optional_image="Hubble-Sunglasses-PCP-Inset-Lifestyle-Image.jpg"
              color="green"
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Sunglasses

export const query = graphql`
  query {
    hubbleapi {
      hubbleFrameProducts {
        title
        handle
        shopifyProductId
        description
        collectionString
        glassesBasePrice
        sunglassesBasePrice
        sunglassesAvailable
        sunglassesMaleHeaderImage1
        sunglassesFemaleHeaderImage1
        productGender
        maleHeaderImage1
        maleHeaderImage2
        size
        shape
        femaleHeaderImage1
        femaleHeaderImage2
        productImage
        material
        materialFilter
        productClothImage
        sortOrder
        relatedFrames {
          edges {
            node {
              title
              productImage
              hubbleFrameVariants {
                title
                color
                colorFilter
                heroColor
                productImage1
                productImage2
                sunglassesWideImage1
                sunglassesWideImage2
                sunglassesWideImage3
                sunglassesProductImage1
                sunglassesProductImage2
                sunglassesProductImage3
                sunglassesProductImage4
              }
            }
          }
        }
        hubbleFrameVariants {
          title
          shopifyVariantId
          color
          colorFilter
          sku
          heroColor
          availableForSale
          productImage1
          productImage2
          productImage3
          sunglassesWideImage1
          sunglassesWideImage2
          sunglassesWideImage3
          sunglassesProductImage1
          sunglassesProductImage2
          sunglassesProductImage3
          sunglassesProductImage4
        }
      }
    }
  }
`
